<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="18" :lg="18" :xl="{span: 7, offset: 0}" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0">
					<a-row>
						
						<!-- <a-col :span="24" :md="12" :lg="12" class="justify-items-center mt-5 pt-5">
							<img src="../../public/images/home-decor-1.jpeg" alt="" width="90%" height="100%" style="border-radius: 20px;"> 
						</a-col> -->
						<a-col :span="24" :md="24" :lg="24">
							<a-row class="text-center">
								<a-col :lg="24">
									
									<img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;"> 
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-10 text-center">Welcome Back!</h3>
							<p class="text-muted text-center mt-0 pt-0">Login to continue</p>

							<a-alert
								v-if="error.show"
								:message="error.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="form"
								class="login-form"
								@submit="handleSubmit"
								:hideRequiredMark="true"
							>
								<a-form-item class="mb-10" label="Email" :colon="false">
									<a-input 
									v-decorator="[
									'username',
									{ rules: [{ required: true, message: 'Please input your username!' }] },
									]" placeholder="Username" />
								</a-form-item>
								<a-form-item class="mb-5" label="Password" :colon="false">
									<a-input
									v-decorator="[
									'password',
									{ rules: [{ required: true, message: 'Please input your password!' }] },
									]" type="password" placeholder="Password" />
								</a-form-item>
								<a-form-item>
									<a-button type="primary" :loading="isloading" block html-type="submit" class="login-form-button" style="background-color: #632163 !important;">
										SIGN IN
									</a-button>
								</a-form-item>
							</a-form>
						</a-col>
					</a-row>
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>    
    
	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				error: {
					message: null,
					show: false,
				},

				isloading: false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {

			handleClose() {
				this.error.show = false;
			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.isloading = true;
						this.error.show = false;
						this.error.message = null;

						let url = `${this.$BACKEND_URL}/auth/investor/login`;
						
						this.$AXIOS.post(url, values).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {
							await localStorage.setItem("user_token", response.data.token)
							await localStorage.setItem("user_details", JSON.stringify(response.data.user))

							// this.notify.message = "You have successfully login";
							// this.notify.type = "success"
							// this.$notify(this.notify)
							this.isloading = false;

							this.$router.push(`/dashboard`);
							}
						}).catch((err) => {
							this.isloading = false;
							
							// this.notify.message = err.response.data.message ?? "Connection error";
							// this.notify.type = "danger"
							// this.$notify(this.notify)
							
							this.error.message = err.response.data.message ?? "Connection error";
							this.error.show = true
						});
					}
				});
			},
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>